<template>
  <div>
    <h1 class="text-h4">Управление продажами</h1>
    <v-divider class="my-2"></v-divider>
    <v-form ref="form" v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-card-title>Заказ {{ order.code }} от {{ order.orderDate }}</v-card-title>
        <v-card-text>
          <drop-down-date-picker
            v-model="form.data.orderDate"
            label="Дата заказа"
            :rules="form.rule.orderDate"
          />
          <v-autocomplete
            v-model="form.data.status"
            dense
            label="Статус заказа"
            persistent-hint
            chips
            clearable
            :items="orderStatusOptions"
            :rules="form.rule.status"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                :color="data.item.color"
                @click="data.select"
              >
                {{ data.item.text }}
              </v-chip>
            </template>
          </v-autocomplete>
          <v-text-field
            v-model="form.data.customerName"
            label="Заказчик"
            counter="150"
            :rules="form.rule.customerName"
            prepend-icon="mdi-account-arrow-right-outline"
          />
          <v-text-field
            v-model="form.data.customerPhone"
            label="Телефон заказчика"
            counter="150"
            :rules="form.rule.customerPhone"
            prepend-icon="mdi-phone-outgoing-outline"
          />
          <v-textarea
            v-model="form.data.customerNotes"
            label="Комментарии к заказу"
            counter="1024"
          ></v-textarea>
          <v-tabs
            v-model="form.data.deliveryType"
            background-color="transparent"
            grow
            show-arrows
            :vertical="$vuetify.breakpoint.name === 'xs'"
            @change="onChangeDeliveryType"
          >
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab key="1" href="#Pickup">Заберу сам</v-tab>
            <v-tab key="2" href="#Delivery">Доставить курьером</v-tab>
          </v-tabs>
          <v-tabs-items v-model="form.data.deliveryType">
            <v-tab-item key="1" value="Pickup">
              <v-select
                v-model="form.data.deliveryShopId"
                :items="deliveryShops"
                item-text="name"
                item-value="id"
                filled
                label="Магазин"
                prepend-icon="mdi-store-outline"
              ></v-select>
              <drop-down-date-picker
                v-model="form.data.pickupDate"
                label="Дата визита в магазин"
              />
              <v-select
                v-model="form.data.pickupTimeSlot"
                :items="pickupTimeOptions"
                item-text="title"
                item-value="id"
                filled
                label="Время визита"
                prepend-icon="mdi-clock-outline"
              ></v-select>
            </v-tab-item>
            <v-tab-item key="2" value="Delivery">
              <v-text-field
                v-model="form.data.recipientName"
                label="Имя получателя"
                counter="150"
                prepend-icon="mdi-account-arrow-left-outline"
              />
              <v-text-field
                v-model="form.data.recipientPhone"
                label="Номер телефона получателя"
                counter="150"
                prepend-icon="mdi-phone-incoming-outline"
              />
              <drop-down-date-picker
                v-model="form.data.deliveryDate"
                label="Дата доставки"
              />
              <v-select
                v-model="form.data.deliveryTimeSlot"
                :items="deliveryTimeOptions"
                item-text="title"
                item-value="id"
                filled
                label="Время доставки"
                prepend-icon="mdi-clock-outline"
              ></v-select>
              <v-text-field
                v-model="form.data.deliveryAddressCity"
                label="Город"
                counter="150"
              />
              <v-text-field
                v-model="form.data.deliveryAddressStreet"
                label="Улица"
                counter="150"
              />
              <v-text-field
                v-model="form.data.deliveryAddressStreetNumber"
                label="Номер дома"
                counter="150"
              />
              <v-text-field
                v-model="form.data.deliveryAddressApartment"
                label="Номер квартиры/офис"
                counter="150"
              />
              <v-textarea
                v-model="form.data.deliveryNotes"
                label="Комментарий курьеру"
                counter="1024"
              ></v-textarea>
              <v-text-field
                v-model="form.data.deliveryPrice"
                label="Цена доставки"
                counter="12"
                :rules="form.rule.deliveryPrice"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :to="{name: 'order.view', params: { id }}">Отмена</v-btn>
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>
<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { mapActions, mapState } from 'vuex'
import OrderUpdateForm from '@/model/order/OrderUpdateForm'
import ToastService from '@/service/ToastService'
import { ruleNumeric, ruleRequired } from '@/utils/FormRules'
import DropDownDatePicker from '@/components/ui/DropDownDatePicker.vue'
import { orderStatusList } from '@/utils/OrderStatus'

export default {
  ...dashboardPageCommonAttributes,
  name: 'OrderEditPage',
  components: { DropDownDatePicker },
  props: {
    id: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      order: {
        code: null,
        orderDate: null
      },
      form: {
        valid: true,
        data: {
          orderDate: null,
          status: null,
          customerName: null,
          customerPhone: null,
          customerNotes: null,
          deliveryType: null,
          deliveryPrice: null,
          recipientName: null,
          recipientPhone: null,
          pickupDate: null,
          pickupTimeSlot: null,
          deliveryDate: null,
          deliveryTimeSlot: null,
          deliveryAddressCity: null,
          deliveryAddressStreet: null,
          deliveryAddressStreetNumber: null,
          deliveryAddressApartment: null,
          deliveryNotes: null,
          deliveryShopId: null
        },
        rule: {
          orderDate: [ruleRequired()],
          status: [ruleRequired()],
          customerName: [ruleRequired()],
          customerPhone: [ruleRequired()],
          deliveryPrice: [ruleNumeric()]
        }
      },
      orderStatusOptions: [],
      pickupTimeOptions: [
        { id: '9_12', title: '9:00 - 12:00' },
        { id: '12_15', title: '12:00 - 15:00' },
        { id: '15_18', title: '15:00 - 18:00' },
        { id: '18_21', title: '18:00 - 21:00' }
      ],
      deliveryTimeOptions: [
        { id: '9_12', title: '9:00 - 12:00' },
        { id: '12_15', title: '12:00 - 15:00' },
        { id: '15_18', title: '15:00 - 18:00' },
        { id: '18_21', title: '18:00 - 21:00' }
      ],
      deliveryShops: []
    }
  },
  computed: {
    ...mapState('order_edit', ['item', 'shops', 'busy'])
  },
  created() {
    this.orderStatusOptions = orderStatusList()

    this.fetchData({ id: this.id })
      .then((_) => {
        this.order = this.item
        this.deliveryShops = this.shops

        this.form.data.orderDate = this.order.orderDate
        this.form.data.status = this.order.status
        this.form.data.customerName = this.order.customerName
        this.form.data.customerPhone = this.order.customerPhone
        this.form.data.customerNotes = this.order.customerNotes
        this.form.data.deliveryType = this.order.deliveryType
        if (this.order.deliveryType === 'Pickup') {
          this.form.data.pickupDate = this.order.deliveryDate
          this.form.data.pickupTimeSlot = this.order.deliveryTimeSlot
          this.form.data.deliveryShopId = this.order.deliveryShop?.id ?? null
        } else if (this.order.deliveryType === 'Delivery') {
          this.form.data.deliveryPrice = this.order.deliveryPrice
          this.form.data.recipientName = this.order.recipientName
          this.form.data.recipientPhone = this.order.recipientPhone
          this.form.data.deliveryDate = this.order.deliveryDate
          this.form.data.deliveryTimeSlot = this.order.deliveryTimeSlot
          this.form.data.deliveryAddressCity = this.order.deliveryAddressCity
          this.form.data.deliveryAddressStreet = this.order.deliveryAddressStreet
          this.form.data.deliveryAddressStreetNumber = this.order.deliveryAddressStreetNumber
          this.form.data.deliveryAddressApartment = this.order.deliveryAddressApartment
          this.form.data.deliveryNotes = this.order.deliveryNotes
        }
      })
  },
  methods: {
    ...mapActions('order_edit', ['fetchData', 'updateItem']),
    onChangeDeliveryType() {
      // this.$refs.form.resetValidation()
    },
    submitForm() {
      const { data } = this.form

      if (this.form.data.deliveryType === 'Pickup') {
        data.deliveryDate = this.form.data.pickupDate
        data.deliveryTimeSlot = this.form.data.pickupTimeSlot
        data.recipientName = null
        data.recipientPhone = null
        data.deliveryAddressCity = null
        data.deliveryAddressStreet = null
        data.deliveryAddressStreetNumber = null
        data.deliveryAddressApartment = null
        data.deliveryNotes = null
      } else if (this.form.data.deliveryType === 'Delivery') {
        data.deliveryShopId = null
      }
      const form = OrderUpdateForm.create({
        ...this.item,
        ...data
      })

      this.updateItem({ form }).then(() => {
        ToastService.success('Данные сохранены')

        this.$router.push({ name: 'order.view', params: { id: this.id } })
      })
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })

      // new Promise((_) => {
      //   this.updateItem()
      //   ToastService.success('Form submitted')
      //
      //   this.$router.push({ name: 'order.view', params: { id: this.id } })
      // }).then()
    }
  }
}
</script>
